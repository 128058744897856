<template>
  <div>

    <div class="table">
      <el-row type="flex" align="middle">
        <el-col :span="24">
          <table border class="form" v-for="(item, i) in len" :key="i">
            <tr v-if="artFormList[i]" class="bg" style="margin-top: 30px;">
              <td rowspan="4" class="edit">
                平行志愿{{ i + 1 }} <br>
                <el-popover placement="bottom" width="200" trigger="click" popper-class="popover-f">
                  <div class="popover-box"> 
                    <p class="box-title">填报为</p>
                    <span v-for="h in len" :key="h" class="box-item" @click="move(artFormList[i], h - 1)">{{ h }}</span>

                    <!-- <p v-for="h in len" :key="h" class="box-item" @click="move(artFormList[i], h - 1)">
                      平行志愿{{ h  }}
                    </p> -->
                  </div>
                  <el-button v-if="record.indexOf(item.select_code) > -1" slot="reference" size="mini" round plain
                    class="btn schoolBtn">
                    志愿{{ record.indexOf(item.select_code) }}
                  </el-button>
                  <el-button v-else slot="reference" size="mini" round plain>换位</el-button>
                </el-popover>
                <br>
                <el-button size="mini" round plain type="danger" @click="btnDelSpecialty(item)">删除</el-button>
              </td>
              <td colspan="4">
                <span class="width_1"> {{ artFormList[i].name }} [{{ artFormList[i].school_id }}] </span>
                <span class="width_2"> 办学性质：{{ artFormList[i].nature }}
                  所在地：{{ artFormList[i].city_name }} 录取规则：{{ artFormList[i].lqgz }}</span>
              </td>
            </tr>

            <tr v-else class="bg">
              <td rowspan="3" class="edit">
                平行志愿{{ i + 1 }} <br>
                <el-button size="mini" round plain>换位</el-button> <br>
                <el-button size="mini" round plain type="danger">删除</el-button>
              </td>
              <td colspan="4">
              </td>
            </tr>

            <tr class="bg">
              <td width="180px">
                名称/专业代号
              </td>
              <td width="500px">
                专业备注
              </td>
              <td width="100px">
                专业计划
              </td>
              <td width="100px">
                学费标准
              </td>
            </tr>
            <tr v-if="artFormList[i]">
              <td>
                {{ artFormList[i].specialty_name }} [{{ artFormList[i].specialty_id }}]
              </td>
              <td>
                {{ artFormList[i].remarks }}
              </td>
              <td>
                {{ artFormList[i].plan_num }}
              </td>
              <td>
                {{ artFormList[i].tuition }}
              </td>
            </tr>

            <tr v-if="artFormList[i]" style="line-height: 100px;">
              <td colspan="4">
                <span v-if="artFormList[i].min_score_1">
                  2023年最低分：{{ artFormList[i].min_score_1 }},
                  录取规则：文化分 ×{{ artFormList[i].w_scale_1 / 100 }} + 专业分 ×{{ artFormList[i].z_scale_1 / 100 }} <strong
                    style="color: RED;">|</strong>
                </span>

                <span v-if="artFormList[i].min_score_2">
                  2022年最低分：{{ artFormList[i].min_score_2 }},
                  录取规则：文化分 ×{{ artFormList[i].w_scale_2 / 100 }} + 专业分 ×{{ artFormList[i].z_scale_2 / 100 }} <strong
                    style="color: RED;">|</strong>
                </span>

                <span v-if="artFormList[i].min_score_3">
                  2021年最低分：{{ artFormList[i].min_score_3 }},
                  录取规则：文化分 ×{{ artFormList[i].w_scale_3 / 100 }} + 专业分 ×{{ artFormList[i].z_scale_3 / 100 }}
                </span>
              </td>

            </tr>
            <tr v-else>
              <td>
              </td>
              <td>
              </td>
              <td>
              </td>
              <td>
              </td>
            </tr>
          </table>
        </el-col>
      </el-row>
    </div>

    <div>
      <el-row>
        <el-col :offset="20" :span="4">
          <div class="refresh" @click="save()"> 保存到志愿表 </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
export default {
  name: '',
  props: ['art_batch'],
  data() {
    return {
      len: 45,
      word: ["一", "二", "三", "四", "五", "六", "七", "八", "九", "十", '十一', '十二'],
      dialogVisibles: false,
      reportList: [],
      record: []
    };
  },
  computed: {
    ...mapState(["userInfo", 'artFormList']),
  },
  filters: {
    test(e) {
      if (!e) {
        return ''
      } else {
        return e
      }
    }
  },
  created() {
    this.$emit("Nav", 3);
    this.setRecord()
  },
  methods: {
    ...mapMutations(['setArtFromList']),
    save() {
      let data = {
        is_wenli: this.userInfo.is_wenli,
        art_batch: this.art_batch,
        content: JSON.stringify(this.artFormList)
      }
      this.$fecth
        .post("art_t_user_report/save", data)
        .then(() => {
          this.$message({
            type: "success",
            message: "保存成功",
          });
        })
    },

    setRecord() {
      this.artFormList.forEach((el, i) => {
        if (el) {
          this.record[i] = el.select_code
        }
      });
      this.$forceUpdate()
    },
    move(e, i) {
      let data = this.artFormList
      let index = this.record.indexOf(e.select_code)
      //判断移动的位置是否被填报过 有的话删除
      if (index > -1) {
        this.record[index] = null
        data[index] = null
      }
      data[i] = e
      this.record[i] = e.select_code
      this.setArtFromList(data)
      this.$forceUpdate()
      this.visible = false
    },
    btnDelSpecialty(item) {
      let data = this.artFormList
      this.record[item - 1] = null
      data[item - 1] = null
      this.setArtFromList(data)
      this.$forceUpdate()
    }
  },
};
</script>

<style lang="less">
.toobDiv {

  .el-button--mini,
  .el-button--small {
    font-size: 16px;
  }

  .el-button--mini,
  .el-button--mini.is-round {
    padding: 4px 4px;
  }
}

.dialogName {
  .content {
    padding: 40px 100px;
    visibility: middle;
    border-bottom: #ddd solid 1px;
    margin-bottom: 10px;
  }

  p {
    margin-bottom: 10px;
  }

  .el-dialog__header {
    border-bottom: #ddd solid 1px;
  }

  .el-dialog__body {
    padding: 0;
    font-size: 16px;
  }
}
</style>


<style scoped lang='less'>
.table {
  table {
    font-size: 14px;
    width: 100%;
    border-collapse: collapse;
    margin-top: 30px;
    border: 1px solid #dddddd;
    margin-bottom: 10px;
  }
}

.refresh {
  background: #409eff;
  color: white;
  display: inline-block;
  border-radius: 30px;
  padding: 8px 30px;
  margin-bottom: 20px;
}

.form {
  td {
    line-height: 35px;
    padding: 5px 13px;
    height: 40px;
  }

  .width_1 {
    color: black;
    margin-right: 10px;
  }

  .width_2 {
    font-size: 14px;
    color: rgb(100, 100, 100);
  }

  .edit {
    background: white;
    text-align: center;
  }
}


.bg {
  background: #f4f7fc;
}


.popover-f {
  width: 70px;
  background-color: #3d3d3c;
  color: white;
  min-width: 0;

  .popper__arrow {
    display: none;
  }

  .box-title {
    padding-bottom: 10px;
    border-bottom: 1px solid white;
  }

  .box-item {
    border: 1px solid #dfdfdf;
    display: inline-block;
    padding: 5px 10px;
    margin-left: 10px;
    margin-bottom: 10px;
    font-size: 14px;
  }

  .box-item:hover {
    background: #f4f7fc;
  }
}
</style>
